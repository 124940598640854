export const useToolsUtil = ()=> {

    const timer = (time: number)=> new Promise((resolve)=>{
        setTimeout(()=>{
            resolve(null)
        }, time)
    })

    return {
        timer
    }
} 

