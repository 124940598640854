import { IEnvironmentVarsUtil, useEnvironmentVarsUtil } from "./useEnvironmentVars.util"
import { IFileUtil, useFileUtil } from "./useFile.util"
import { ILocalStorageUtil, useLocalStorageUtil } from "./useLocalStorage.util"
import { IResponseUtil, useResponseUtil } from "./useResponse.util"
import { IResultUtil, useResultUtil } from "./useResult.util"
import { IServiceUtil, useServiceUtil } from "./useServices.util"
import { ITokenUtil, useTokenUtil } from "./useToken.util"

export interface IUtil {
    environmentVars: IEnvironmentVarsUtil;
    token: ITokenUtil;
    response: IResponseUtil;
    result: IResultUtil;
    service: IServiceUtil;
    localStorage: ILocalStorageUtil
    file: IFileUtil
}

export const useUtil = (): IUtil => {

    const environmentVars = useEnvironmentVarsUtil()
    const token = useTokenUtil()
    const response = useResponseUtil()
    const result = useResultUtil()
    const service = useServiceUtil()
    const localStorage = useLocalStorageUtil()
    const file = useFileUtil()

    return {
        environmentVars, 
        token, 
        response, 
        result, 
        service,
        localStorage,
        file
    }
} 