import { Button, Card, CardActions, CardContent, CardMedia, Grid, TextField } from "@mui/material"
import { FC, useRef, useState } from "react"
import * as yup from 'yup'

const emailValidator = yup.string().required("Por favor ingresa un email").email("El email no tiene un formato válido")
const passwordValidator = yup.string().required("Por favor ingrese su clave").min(6, "La clave debe contener al menos 8 caracteres").max(100, "La clave no debe tener mas de 100 caracteres")

interface ILoginFormComponentProps {
    publicUrl: string
    onSubmit: (account: { email: string, password: string }) => void
}

export const LoginFormComponent: FC<ILoginFormComponentProps> = (props) => {
    
    const passwordRef = useRef<HTMLInputElement>();

    const [emailState, setEmailState] = useState<string>('')
    const [passwordState, setPasswordState] = useState<string>('')
    const [isEmailErrorState, setIsEmailErrorState] = useState<boolean>(false)
    const [isPasswordErrorState, setIsPasswordErrorState] = useState<boolean>(false)

    const onChangeEmail = (value: string) => {
        setEmailState(value)
    }

    const onChangePassword = (value: string) => {
        setIsPasswordErrorState(!passwordValidator.isValidSync(value))
        setPasswordState(value)
    }

    const onClickSubmitHandler = () => {
        props.onSubmit({ email: emailState, password: passwordState })
    }

    const onPasswordEnterHandler = (key: string) => {
        key === 'Enter' && onClickSubmitHandler()
    }

    const onEmailEnterHandler = (key: string) => {
        key === 'Enter' && passwordRef?.current && passwordRef.current.focus()
    }
    
    const validatorListComputed: Array<boolean> = [
        passwordValidator.isValidSync(passwordState)
    ]

    const isInvalidFormComputed: boolean = validatorListComputed.some(value => value === false)
 
    return (
        <Card sx={{ borderRadius: 3 }} >
            <Grid container display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly" padding="20px" >
                <Grid item flexDirection="row" justifyContent="center">
                    <CardMedia
                        component="img"
                        image={`${props.publicUrl}/img/logo-autung.png`}
                        alt="logo-autung"
                        style={{ margin: "0% 25%", width: "50%" }}
                    />
                </Grid>
                <Grid item  >
                    <CardContent>
                        <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing={2} >
                            <Grid item>
                                <TextField
                                    type="text"
                                    size="small"
                                    label="Usuario"
                                    variant="filled"
                                    value={emailState}
                                    onChange={e => onChangeEmail(e.target.value)}
                                    onKeyDown={e => onEmailEnterHandler(e.key)}
                                    error={isEmailErrorState}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    inputRef={passwordRef}
                                    type="password"
                                    size="small"
                                    label="Clave"
                                    variant="filled"
                                    value={passwordState}
                                    onChange={e => onChangePassword(e.target.value)}
                                    onKeyDown={e => onPasswordEnterHandler(e.key)}
                                    error={isPasswordErrorState}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item >
                    <CardActions>
                        <Button
                            color="secondary"
                            size="medium"
                            variant="outlined"
                            sx={{
                                borderRadius: 28,
                                borderColor: "grey.500"
                            }}
                            onClick={onClickSubmitHandler}
                            disabled={isInvalidFormComputed}
                        >
                            Ingresar
                        </Button>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    )
}