import { IBook } from "../models/book.model"

export const bookFactory = (): IBook => ({
    id: 0,
    cover: "",
    title: "",
    description: "",
    url: "",
    status: {
        enable: false,
        lastPage: 0,
        lastSeenPage: 0
    }
})