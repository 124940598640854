import { Backdrop, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useToolsUtil } from "../utils/useTools.util"

export const LoaderComponent = (props: { isOpen: boolean }) => {

    const toolsUtil = useToolsUtil()

    const [isRendered, setIsRendered] = useState<boolean>(false)
    const [isOpenAnimation, setIsOpenAnimation] = useState<boolean>(false)

    useEffect(() => {
        if (props.isOpen !== isRendered) changeAnimation(props.isOpen)
    }, [props.isOpen])

    const changeAnimation = async (isOpen: boolean) => {
        if (props.isOpen) {
            setIsRendered(true)
            await toolsUtil.timer(50)
            setIsOpenAnimation(true)
        } else {
            setIsOpenAnimation(false)
            await toolsUtil.timer(300)
            setIsRendered(false)
        }
    }

    return (
        <div>
            {isRendered ? (
                <Backdrop open={isOpenAnimation} style={{ zIndex: 999 }}>
                    <CircularProgress />
                </Backdrop>
            ) : null}
        </div>
    )
}