import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from "@mui/material"
import { deepOrange } from "@mui/material/colors"
import { FC } from "react"
import { IBook } from '../models/book.model'

interface IBookComponentProps {
    book: IBook
    onClick: (book: IBook) => void
}

export const BookComponent: FC<IBookComponentProps> = (props) => {

    const onClickHandler = () => props.onClick(props.book)

    return (
        <Card sx={{ borderRadius: 3, height: "100%" }}>
            <Grid container justifyContent="space-around" sx={{height: "100%"}}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: deepOrange[500] }}>A</Avatar>
                        }
                        title="Autung"
                        titleTypographyProps={{ fontWeight: "500", fontSize: "20px" }}
                    />
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardMedia
                        component="img"
                        height="194"
                        image={props.book.cover}
                        alt="cover"
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardContent>
                        <Grid container rowSpacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h6">{props.book.title} </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body1">{props.book.description}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>

                <Grid item alignSelf="self-end" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardActions>
                        <Button
                            color="secondary"
                            size="medium"
                            variant="outlined"
                            sx={{ borderRadius: 28, borderColor: "grey.500" }}
                            onClick={()=>onClickHandler()}
                            disabled={props.book.status.enable === false}
                        >
                            Abrir
                        </Button>
                    </CardActions>
                </Grid>
            </Grid>
        </Card >
    )
}