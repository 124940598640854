import { useState } from "react"
import { useHistory } from "react-router"
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Button, Drawer, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Modal, Stack, Typography } from "@mui/material"
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IAuthStore } from "../stores/useAuth.store"
import { IUserStore } from "../stores/useUser.store"

const getStyleOfTheDrawerItem = {
    text: {
        color: 'white',
        cursor: 'pointer'
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        width: '250px',
        cursor: 'pointer'
    },
    icon: {
        color: 'white',
    }
}

const styleSingOutModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 200,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
}

interface INavigationComponentProps {
    authStore: IAuthStore
    userStore: IUserStore
}

const NavigationComponent = (props: INavigationComponentProps) => {

    const history = useHistory()

    const [isOpenSlideMenu, setIsOpenSlideMenu] = useState<boolean>(false)
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

    const onOpenSlideHandler = () => {
        setIsOpenSlideMenu(true)
    }

    const onRedirectHandler = (url: string) => {
        setIsOpenSlideMenu(false)
        history.push(url)
    }

    const onClickOpenModalHandler = () => {
        setIsOpenSlideMenu(false)
        setIsOpenModal(true)
    }

    const onCloseModalHandler = () => {
        setIsOpenModal(false)
    }

    const onClickSingOutHandler = () => {
        setIsOpenModal(false)
        props.authStore.signOut()
        history.push('/')
    }

    if (props.authStore.isAuthenticated) return (
        <>
            <Modal open={isOpenModal} onClose={() => onCloseModalHandler()} >
                <Grid
                    container
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-evenly"
                    sx={styleSingOutModal}
                >
                    <Grid item>
                        <Typography variant="h6">Cerrar Sesión</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">¿Querés cerrar la sesión?</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container flexDirection="row" columnSpacing={2}>
                            <Grid item>
                                <Button disabled={false} onClick={() => onCloseModalHandler()} variant="contained" color="success" size="small">
                                    No
                                </Button>
                            </Grid>
                            <Grid item >
                                <Button disabled={false} onClick={() => onClickSingOutHandler()} variant="contained" color="primary" size="small">
                                    Si
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>

            <AppBar position="sticky" color="primary">
                <Toolbar variant="dense"    >
                    <IconButton
                        size="large"
                        edge="start"
                        color="default"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => onOpenSlideHandler()}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer
                open={isOpenSlideMenu}
                anchor={"left"}
                onClose={() => setIsOpenSlideMenu(false)}
            >
                {props.userStore.user.role === "professor" || props.userStore.user.role === "admin" ? (
                    <Link onClick={() => onRedirectHandler('/books')} style={getStyleOfTheDrawerItem.link}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AutoStoriesIcon style={getStyleOfTheDrawerItem.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Mis Libros" style={getStyleOfTheDrawerItem.text} />
                            </ListItem>
                        </List>
                    </Link>
                ): null}

                {props.userStore.user.role === "student" ? (
                    <Link onClick={() => onRedirectHandler('/activities')} style={getStyleOfTheDrawerItem.link}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <BorderColorIcon style={getStyleOfTheDrawerItem.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Actividades" style={getStyleOfTheDrawerItem.text} />
                            </ListItem>
                        </List>
                    </Link>
                ): null}

                <Link onClick={() => window.open('http://137.184.244.10:2024/')}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <BorderColorIcon style={getStyleOfTheDrawerItem.icon} />
                            </ListItemIcon>
                            <ListItemText primary="Codyblock" style={getStyleOfTheDrawerItem.text} />
                        </ListItem>
                    </List>
                </Link>
                <Link onClick={() => onClickOpenModalHandler()} style={getStyleOfTheDrawerItem.link}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LogoutOutlinedIcon style={getStyleOfTheDrawerItem.icon} />
                            </ListItemIcon>
                            <ListItemText primary="Cerrar Sesión" style={getStyleOfTheDrawerItem.text}>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Link>
            </Drawer>
        </>
    )

    return null
}

export { NavigationComponent }