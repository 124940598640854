import { useState } from "react"
import { IActivityService } from "../services/useActivity.service"
import { IResult, IResultUtil } from "../utils/useResult.util"
import { IActivity } from "../models/activity.model"

interface IUseActivityStoreProps {
    activityService: IActivityService
    resultUtil: IResultUtil
}

export interface IActivityStore {
    activityList: Array<IActivity> // puede que lo renombre
    getActivityList: (userId: number) => Promise<IResult<Array<IActivity>>> // puede que lo renombre
    update: (activity: IActivity) => Promise<IResult<null>>
}

export const useActivityStore = (props: IUseActivityStoreProps): IActivityStore => {

    const [activityList, setActivityList] = useState<IActivityStore['activityList']>([])

    const getActivityList: IActivityStore['getActivityList'] = async (userId) => {
        const response = await props.activityService.getList()
        if (response.isError) return props.resultUtil.error([])
        setActivityList(response.data)
        return props.resultUtil.success([])
    }

    const update: IActivityStore['update']  = async (activity) => {
        const response = await props.activityService.update(activity)
        if(response.isError) return props.resultUtil.error(null)
        return props.resultUtil.success(null)
    }

    return {
        activityList,
        getActivityList,
        update
    }
}