import { FC, useEffect } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from "@mui/material/styles";
import theme from './theme';
import Router from './Router';

import { LoaderComponent } from './components/loader.component';
import { Alert, Snackbar } from '@mui/material';
import { useUtil } from './utils';
import { useService } from './services';
import { useStore } from './stores';

const App: FC = () => {

  const util = useUtil()
  const service = useService({util})
  const store = useStore({util, service})

  useEffect(()=> {
    onInit()
  }, [])

  const onInit = async () => {
    const signInCheckResult = await store.auth.signInCheck()
    if(signInCheckResult.isError) return
    const getUserResult =  await store.user.getUser()
    if(getUserResult.isError) return
  }

  return (
    <ThemeProvider theme={theme}>

      <LoaderComponent isOpen={store.app.isApiLoading} />

      <Snackbar open={store.app.isApiError} autoHideDuration={6000} onClose={()=>{}}>
        <Alert severity="error">Error</Alert>
      </Snackbar>

      <Snackbar open={store.app.isApiSuccess} autoHideDuration={6000} onClose={()=>{}}>
        <Alert severity="success">Success</Alert>
      </Snackbar>

      <Router
        publicUrl={util.environmentVars.publicUrl}
        store={store}
      />
      
    </ThemeProvider>
  );
}

export default App;