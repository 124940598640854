import { userFactory } from "../factories/user.factory"
import { IUser, TUsers } from "../models/user.model"
import { IResponseUtil } from "../utils/useResponse.util"
import { IServiceResponse, IServiceUtil } from "../utils/useServices.util"
import { ITokenUtil } from "../utils/useToken.util"

interface IUseUserServiceProps {
    baseUrl: string
    serviceUtil: IServiceUtil
    responseUtil: IResponseUtil
    tokenUtil: ITokenUtil
}

export interface IUserService {
    getUser: () => Promise<IServiceResponse<TUsers>>
    getChildren: (childrenId: Array<number>)=> Promise<IServiceResponse<Array<TUsers>>>
}

export const useUserService = (props: IUseUserServiceProps): IUserService => {

    const getUser: IUserService['getUser'] = async () => {
        // mas adelante hay que comprobar primero si el token es valido para no hacer busquedas innecesarias

        const token = props.tokenUtil.get()

        const response = await props.serviceUtil.get<TUsers>({
            url: `${props.baseUrl}/user`,
            headers: {
                Authorization: `${props.tokenUtil.get()}`
            }
        })

        if(response.isError) return props.responseUtil.error({
            data: userFactory(),
            status: response.status
        })
        
        if(response.isSuccess && !response.data ) return props.responseUtil.error({
            data: userFactory(),
            status: 404
        })
            
        return  props.responseUtil.success({
            data: response.data,
            status: 200
        })
    }

    const getChildren: IUserService['getChildren'] = async (childrenId)=> {

        const promiseList = childrenId.map(childId => 
            props.serviceUtil.get<TUsers>({
                url: `${props.baseUrl}/user/${childId}`,
                headers: {
                    Authorization: `Bearer ${props.tokenUtil.get()}`
                }
            })
        )

        const promiseResponseList = await Promise.all(promiseList)

        const isError = promiseResponseList.some(childResponse => childResponse.isError)


        if(isError) return  props.responseUtil.error({
            data: [],
            status: 500
        })

        return props.responseUtil.success({
            status: 200,
            data: promiseResponseList.map(childResponse=>childResponse.data) 
        })
    }

    return { getUser, getChildren }
}