import { FormControl, Grid, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent, TablePagination } from '@mui/material'
import { FC } from 'react'


interface IPaginatedComponentProps {
    currentPage: number
    totalPages: number
    onChangePage: (pageNumber: number) => void
    onChangeRowsPerPage: (value: number) => void
    rowsPerPage: number
}


export const PaginatedComponent: FC<IPaginatedComponentProps> = props => {

    const onChangePageHandler = (_: React.ChangeEvent<unknown>, value: number) => {
        props.onChangePage(value)
    }


    const onChangeRowsPerPageHandler = (event: SelectChangeEvent) => {
        props.onChangeRowsPerPage(Number(event.target.value))
    }

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" marginTop="30px">
                <Pagination
                    count={props.totalPages}
                    color='primary'
                    variant="outlined"
                    page={props.currentPage + 1}
                    onChange={onChangePageHandler}
                />
            </Grid>
            <Grid container justifyContent="center" alignItems="center" marginTop="20px">
                <Grid item xs={12} sm={12} md={3} lg={2} xl={1}>
                    <FormControl fullWidth>
                        <InputLabel id="childrenSelectLabel">Resultados por página</InputLabel>
                        <Select
                            value={String(props.rowsPerPage)}
                            label="Resultados por pagina"
                            onChange={onChangeRowsPerPageHandler}
                            size='small'
                        >
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="12">12</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}