import { useState } from "react"

interface IApiData {
    url: string,
    status: 'init' | 'success' | 'error'
}

export interface IAppStore {
    isApiLoading: boolean,
    isApiError: boolean,
    isApiSuccess: boolean,
    onApiAction: (param: IApiData ) => void
}

export const useAppStore = (): IAppStore => {

    const [apiDataListState, setApiDataListState] = useState<Array<IApiData>>([])

    const isApiLoading: IAppStore['isApiLoading'] = apiDataListState.some(apiData => apiData.status === 'init')
    const isApiError: IAppStore['isApiError'] = apiDataListState.some(apiData => apiData.status === 'error')
    const isApiSuccess: IAppStore['isApiSuccess'] = apiDataListState.some(apiData => apiData.status === 'success')


    const onApiAction: IAppStore['onApiAction'] = param => {
        if (param.status === 'init') {
           setApiDataListState((apiDataListStateCurrent)=>[...apiDataListStateCurrent, {...param}])
        }

        if(param.status === 'success') {
            setApiDataListState((apiDataListStateCurrent)=>apiDataListStateCurrent.map(apiData=>apiData.url === param.url ? param : apiData))
            deleteApiDataWithDelay(param)
        } 

        if(param.status === 'error') {
            setApiDataListState(apiDataListStateCurrent=>apiDataListStateCurrent.map(apiData=>apiData.url === param.url ? param : apiData))
            deleteApiDataWithDelay(param)
        }
    }

    const deleteApiDataWithDelay = (param: IApiData) => {
        setTimeout(()=> {
            setApiDataListState((apiDataListStateCurrent) => apiDataListStateCurrent.filter(apiData=> apiData.url !== param.url))
        }, 3000)
    }

    return {
        isApiLoading,
        isApiError,
        isApiSuccess,
        onApiAction,
    }
}