import { IFile } from "../models/file.model"
import { IResponseUtil } from "../utils/useResponse.util"
import { IServiceResponse, IServiceUtil } from "../utils/useServices.util"
import { ITokenUtil } from "../utils/useToken.util"

interface IFileServiceProps {
    baseUrl: string
    serviceUtil: IServiceUtil
    responseUtil: IResponseUtil
    tokenUtil: ITokenUtil
}

export interface IFileService {
    get: (id: number) => Promise<IServiceResponse<IFile>>
    post: (file: IFile) => Promise<IServiceResponse<IFile>>
}

export const useFileService = (props: IFileServiceProps): IFileService => {

    const get: IFileService['get'] = async ( id ) => {
        const response = props.serviceUtil.get<IFile>({
            url: `${props.baseUrl}/files/${id}`,
            headers: {
                Authorization: `Bearer ${props.tokenUtil.get()}`
            }
        })

        return response
    }

    const post: IFileService['post'] = async (file) => {

        const response = props.serviceUtil.post<IFile>({
            url: `${props.baseUrl}/files`,
            headers: {
                Authorization: `Bearer ${props.tokenUtil.get()}`
            },
            data: file
        })

        return response
    }

    return { get, post }
}