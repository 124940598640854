import { useState } from "react"
import { bookFactory } from "../factories/book.factory"
import { IBook } from "../models/book.model"
import { IBookService } from "../services/useBook.service"
import { IResult, IResultUtil } from "../utils/useResult.util"

interface IUseBookStoreProps {
    bookService: IBookService
    resultUtil: IResultUtil
}

export interface IBookStore {
    bookList: Array<IBook>
    getBookList: ()=> Promise<IResult<Array<IBook>>>
    getBook: (id: number)=>  Promise<IResult<IBook>>
}

export const useBookStore = (props: IUseBookStoreProps): IBookStore => {

    const [bookList, setBookList] = useState<IBookStore['bookList']>([])

    const getBookList: IBookStore['getBookList'] = async () => {
        const response = await props.bookService.getBookList()
        if(response.isError) return props.resultUtil.error([])
        setBookList(response.data)
        return props.resultUtil.success(response.data)
    }

    const getBook: IBookStore['getBook'] = async (id) => {
        const bookFound = bookList.find(book=> book.id === id)
        if(bookFound) return props.resultUtil.success(bookFound)
        const response = await props.bookService.getBook(id)
        if(response.isError) return props.resultUtil.success(bookFactory())
        return  props.resultUtil.success(response.data)
    }
    
    return {
        bookList,
        getBookList,
        getBook
    }
}