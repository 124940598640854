import { IService } from "../services"
import { IUtil } from "../utils"
import { IActivityStore, useActivityStore } from "./useActivity.store"
import { useAppStore, IAppStore } from "./useApp.store"
import { IAuthStore, useAuthStore } from "./useAuth.store"
import { IBookStore, useBookStore } from "./useBook.store"
import { IFileStore, useFileStore } from "./useFile.store"
import { IUserStore, useUserStore } from "./useUser.store"

export interface IStoreProps {
    util: IUtil
    service: IService
}

export interface IStore {
    auth: IAuthStore
    user: IUserStore
    app: IAppStore
    book: IBookStore
    file: IFileStore
    activity: IActivityStore
}

export const useStore = (props: IStoreProps): IStore  => {

    const app = useAppStore()

    const book = useBookStore({ 
        bookService: props.service.book, 
        resultUtil: props.util.result
    })

    const file = useFileStore({
        fileService: props.service.file, 
        resultUtil: props.util.result,
        fileUtil: props.util.file
    })

    const activity = useActivityStore({
        activityService: props.service.activity,
        resultUtil: props.util.result
    })

    const user = useUserStore({
        userService: props.service.user,
        resultUtil: props.util.result,
        localStorageUtil: props.util.localStorage
    })

    const auth = useAuthStore({
        authService: props.service.auth,
        resultUtil: props.util.result, 
        tokenUtil: props.util.token
    })
    // cambiar esto para que en vez de usar un callback se use un evento global para simplificar el usao del index este
    props.util.service.addCallback((param: { url: string; status: "init" | "success" | "error" }) => app.onApiAction(param))
  
    return {
        app,
        auth,
        book,
        file,
        user,
        activity
    }
}

