export interface ILocalStorageUtil {
    get: <TData>(key: string, defaultData: TData) => TData
    set: (key: string, data: any) => void
}

export const useLocalStorageUtil = (): ILocalStorageUtil => {

    const get: ILocalStorageUtil['get'] = (key, defaultData) => {
        const result = localStorage.getItem(key)
        if(!result) return defaultData
        return JSON.parse(result)
    }

    const set: ILocalStorageUtil['set'] = (key, data) => {
        const jsonData = JSON.stringify(data)
        localStorage.setItem(key, jsonData)
    } 

    return {
        get,
        set
    }
}