import { SearchOutlined } from "@mui/icons-material"
import { Box, Grid, InputAdornment, TextField } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { BookComponent } from "../components/book.component"
import { PaginatedComponent } from "../components/paginated.component"
import { IBook } from "../models/book.model"
import { IBookStore } from "../stores/useBook.store"

interface IBooksViewProps {
    bookStore: IBookStore
    onNavigate: (url: string) => void
}

export const BooksView: FC<IBooksViewProps> = props => {

    const [currentPageState, setCurrenPageState] = useState<number>(0)
    const [rowsPerPageState, setRowsPerPageState] = useState<number>(3)
    const [searchedBookState, setSearchedBookState] = useState<string>("")

    useEffect(() => {
        onInit()
    }, [])

    useEffect(() => {
        setCurrenPageState(0)
    }, [props.bookStore.bookList])

    const onInit = () => {
        props.bookStore.getBookList()
    }

    const onChangePageHandler = (value: number) => setCurrenPageState(--value)

    const onChangeRowsPerPageHandler = (value: number) => {
        setCurrenPageState(0)
        setRowsPerPageState(value)
    }

    const onChangeInputSearchHandler = (value: string) => {
        setSearchedBookState(value)
    }

    const onClickBookHandler = (book: IBook) => {
        props.onNavigate(`/book/${book.id}`)
    }

    const bookListFilteredComputed = searchedBookState ? props.bookStore.bookList.filter(book => book.title.toLowerCase().includes(searchedBookState.toLowerCase()))
        : props.bookStore.bookList

    const totalPagesComputed = Math.ceil(bookListFilteredComputed.length / rowsPerPageState)

    const initPagePositionComputed = currentPageState * rowsPerPageState

    const endPagePositionComputed = initPagePositionComputed + rowsPerPageState

    const bookListPaginatedComputed = bookListFilteredComputed.filter(
        (_, index) => index >= initPagePositionComputed && index < endPagePositionComputed
    )

    return (
        <Box padding={5}>
            <Grid container rowSpacing={5} justifyContent="center">
                <Grid item xs={12}>
                    <TextField
                        type="search"
                        placeholder="Buscar..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            )
                        }}
                        value={searchedBookState}
                        onChange={e => onChangeInputSearchHandler(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2} justifyContent="center">
                        {bookListPaginatedComputed.map(book => (
                            <Grid item key={book.id} xs={12} sm={12} md={6} lg={4} xl={4}>
                                <BookComponent book={book} onClick={onClickBookHandler}/>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <PaginatedComponent
                        currentPage={currentPageState}
                        totalPages={totalPagesComputed}
                        onChangePage={onChangePageHandler}
                        rowsPerPage={rowsPerPageState}
                        onChangeRowsPerPage={onChangeRowsPerPageHandler}
                    />
                </Grid>
            </Grid>
        </Box>
    )
} 