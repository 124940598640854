import { fileFactory } from "../factories/file.factory"
import { IFile } from "../models/file.model"
import { IFileService } from "../services/useFile.service"
import { IFileUtil } from "../utils/useFile.util"
import { IResult, IResultUtil } from "../utils/useResult.util"

interface IUseFileStoreProps {
    fileService: IFileService
    resultUtil: IResultUtil
    fileUtil: IFileUtil
}

export interface IFileStore {
    get: (id: number)=>  Promise<IResult<IFile>>
    post: (file: IFile) => Promise<IResult<IFile>> // este capas lo borro
    download: (id: number) => Promise<IResult<null>>
    upload: ()=> Promise<IResult<IFile>>
}

export const useFileStore = (props: IUseFileStoreProps): IFileStore => {
    // mas adelante, hay que guardar los archivos en el store para que sea menos costoso para el servidor

    const get: IFileStore['get'] = async (id) => {
        const response = await props.fileService.get(id)
        if(response.isError) return props.resultUtil.error(fileFactory())
        return props.resultUtil.success(response.data)
    }

    const post: IFileStore['post'] = async (file) => {
        const response = await props.fileService.post(file)
        if(response.isError) return props.resultUtil.success(fileFactory())
        return props.resultUtil.success(response.data)
    }

    const download: IFileStore['download'] = async (id) => {
        const result = await get(id)
        if(result.isError) return props.resultUtil.error(null)
        props.fileUtil.download(result.data)
        return props.resultUtil.success(null)
    }

    const upload: IFileStore['upload'] = async () => {
        const file = await props.fileUtil.upload()
        if(file=== null) return props.resultUtil.error(fileFactory())
        const result = await post({
            id: 0,
            name: file.name,
            type: file.type,
            base64: await props.fileUtil.parseToBase64(file)
        })
        if(result.isError) return props.resultUtil.error(fileFactory())
        return props.resultUtil.success(result.data)        
    }

    return {
        get,
        post,
        download,
        upload
    }
}