const TOKEN = 'token'

export interface ITokenUtil {
    set: (token: string) => void
    get: () => string
}

export const useTokenUtil = (): ITokenUtil => {

    const set: ITokenUtil['set'] = token => localStorage.setItem(TOKEN, token)
    
    const get: ITokenUtil['get'] = () => {
        const tokenObtained = localStorage.getItem(TOKEN)
        return tokenObtained || ''
    }

    return { get, set }
} 