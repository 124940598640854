import { IBook } from "../models/book.model"
import { IResponseUtil } from "../utils/useResponse.util"
import { IServiceResponse, IServiceUtil } from "../utils/useServices.util"
import { ITokenUtil } from "../utils/useToken.util"
// Todo: Agregar mas logica de negocio a los libros
// Actualmente muestra todos los libros, sin que estos esten filtrados para los alumnos
interface IUseBookServiceProps {
    baseUrl: string
    serviceUtil: IServiceUtil
    responseUtil: IResponseUtil
    tokenUtil: ITokenUtil
}

export interface IBookService {
    getBookList: () => Promise<IServiceResponse<Array<IBook>>>
    getBook: (id: number) => Promise<IServiceResponse<IBook>>
}

export const useBookService = (props: IUseBookServiceProps): IBookService => {

    const getBookList: IBookService['getBookList'] = async () => {
        // falta la seguridad
        const response = props.serviceUtil.get<Array<IBook>>({
            url: `${props.baseUrl}/book/`,
            headers: {
                Authorization: `${props.tokenUtil.get()}`
            }
        })

        return response
    }

    const getBook: IBookService['getBook'] = async (id) => {
        // falta la seguridad
        const response = await props.serviceUtil.get<IBook>({
            url: `${props.baseUrl}/book/${id}`,
            headers: {
                Authorization: `${props.tokenUtil.get()}`
            }
        })

        return response
    }

    return { getBookList, getBook }
}