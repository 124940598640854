import { IUtil } from "../utils"
import { IActivityService, useActivityService } from "./useActivity.service";
import { IAuthService, useAuthServices } from "./useAuth.service"
import { IBookService, useBookService } from "./useBook.service"
import { IFileService, useFileService } from "./useFile.service";
import { IUserService, useUserService } from "./useUser.service";

export interface IServiceProps {
    util: IUtil
}

export interface IService { 
    book: IBookService
    file: IFileService
    activity: IActivityService
    user: IUserService
    auth: IAuthService 
}

export const useService = (props: IServiceProps): IService => ({
    book: useBookService({
        baseUrl: props.util.environmentVars.bookApiUrl,
        responseUtil: props.util.response,
        serviceUtil: props.util.service,
        tokenUtil: props.util.token
    }),
    file: useFileService({
        baseUrl: props.util.environmentVars.fileUrl,
        responseUtil: props.util.response,
        serviceUtil: props.util.service,
        tokenUtil: props.util.token
    }),
    activity: useActivityService({
        baseUrl: props.util.environmentVars.activityUrl,
        responseUtil: props.util.response,
        serviceUtil: props.util.service,
        tokenUtil: props.util.token
    }),
    user: useUserService({
        baseUrl: props.util.environmentVars.userApiUrl,
        responseUtil: props.util.response,
        serviceUtil: props.util.service,
        tokenUtil: props.util.token
    }),
    auth: useAuthServices({
        baseUrl: props.util.environmentVars.authApiUrl,
        responseUtil: props.util.response,
        serviceUtil: props.util.service
    })
})
