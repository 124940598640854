import { useEffect, useState } from "react"
import { userFactory } from "../factories/user.factory"
import { TUsers } from "../models/user.model"
import { IUserService } from "../services/useUser.service"
import { ILocalStorageUtil } from "../utils/useLocalStorage.util"
import { IResult, IResultUtil } from "../utils/useResult.util"

const USER_KEY = 'user'

interface IUseUserStoreProps {
    resultUtil: IResultUtil
    userService: IUserService
    localStorageUtil: ILocalStorageUtil
}

export interface IUserStore {
    user: TUsers
    children: Array<TUsers>
    getUser: () => Promise<IResult<TUsers>>
    getChildren: (childrenId: Array<number>) => Promise<IResult<Array<TUsers>>>
}

export const useUserStore = (props: IUseUserStoreProps): IUserStore => {

    const [user, setUser] = useState<IUserStore['user']>(userFactory())
    const [children, setChildren] = useState<Array<TUsers>>([])

    useEffect(()=>{
        onInit()
    }, [])

    const onInit = () => {
        const userData = props.localStorageUtil.get(USER_KEY, userFactory()) 
        if(userData.id) setUser(userData)
    }

    const getUser: IUserStore['getUser'] = async () => {
        const response = await props.userService.getUser()
        if (response.isError) return props.resultUtil.error(userFactory())
        setUser(response.data)
        props.localStorageUtil.set(USER_KEY, response.data)
        return props.resultUtil.success(response.data)
    }

    const getChildren: IUserStore['getChildren']  = async (childrenId) => {
        const response = await props.userService.getChildren(childrenId)
        if (response.isError) return props.resultUtil.error([])
        setChildren(response.data)
        return props.resultUtil.success(response.data)
    }

    return {
        user,
        children,
        getUser,
        getChildren
    }
}


