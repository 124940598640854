import { IFile } from "../models/file.model"

export interface IFileUtil {
    parseToBase64: (file: File) => Promise<string>
    upload: () => Promise<File | null>
    download: (file: IFile) => void
}


export const useFileUtil = (): IFileUtil => {

    const parseToBase64: IFileUtil['parseToBase64']  = (file: any) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(String(reader.result))
        reader.onerror = reject
    })
    

    const upload: IFileUtil['upload'] = () => new Promise((resolve) => {

        const input = document.createElement('input')
        input.setAttribute("type", "file")
        input.style.display='none'
        document.body.appendChild(input)
        input.click()
        input.onabort = ()=> {
            console.log('cancelo todooo')
        }

        window.addEventListener('focus', () => {
            setTimeout(() => {
                input.parentNode?.removeChild(input);
            }, 300)
        }, { once: true })

        input.onchange = () =>{
            const files = input.files

            if(files && files[0] ) {
               
                const file = files.item(0)

                if(file) {
                    resolve(file)
                }

                resolve(null)
            }
        }
    })
   
    
    const download: IFileUtil['download'] = (file) => {

        const link = document.createElement('a');
        link.style.display='none'
        link.href = file.base64;
        
        link.setAttribute(
          'download',
          file.name + file.type,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode?.removeChild(link);

    }

    return { upload, download, parseToBase64 }
} 