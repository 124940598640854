import { IUser } from "../models/user.model"
import { IResponseUtil } from "../utils/useResponse.util"
import { IServiceResponse, IServiceUtil } from "../utils/useServices.util"

interface IUseAuthServicesProps {
    baseUrl: string
    serviceUtil: IServiceUtil
    responseUtil: IResponseUtil
}

export interface IAuthService {
    signIn: (account: { email: string, password: string }) => Promise<IServiceResponse<string>>
}

export const useAuthServices = (props: IUseAuthServicesProps): IAuthService => {
    const signIn: IAuthService['signIn'] = async (account) => {
        
        const response = await props.serviceUtil.post<IUser>({
            url:`${props.baseUrl}/auth/login`,
            headers: {},
            data: { username: account.email, password: account.password }
        })

        if(response.isError) return props.responseUtil.error({data: '', status: 500})

        if( response.data && response.data.token) return props.responseUtil.success({
            data: response.data.token,
            status: 200
        }) 
        
        return props.responseUtil.success({data: '', status: 200})  
    }

    return {
        signIn
    }
}

