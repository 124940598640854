import { IServiceResponse } from "./useServices.util"

export interface IResponseUtil {
    success: <GData>(props: {data: GData, status?: number }) => IServiceResponse<GData>
    error: <GData>(props: {data: GData, status?: number }) => IServiceResponse<GData>
}

export const useResponseUtil = (): IResponseUtil => {

    const success: IResponseUtil['success'] = props => ({
        isSuccess: true,
        isError: false,
        status: props.status ? props.status : 200,
        data: props.data
    })


    const error: IResponseUtil['error'] = props => ({
        isSuccess: false,
        isError: true,
        status: props.status ? props.status : 500,
        data: props.data
    })

    return { success, error }
}