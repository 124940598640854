import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import { FC, useState } from 'react'
import { LoginFormComponent } from '../components/loginForm.component'
import { IAuthStore } from '../stores/useAuth.store'
import { IUserStore } from '../stores/useUser.store'

interface ISignInViewProps {
    publicUrl: string
    authStore: IAuthStore
    userStore: IUserStore
    onNavigate: (url: string) => void
}

export const SignInView: FC<ISignInViewProps> = (props) => {

    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)

    const onSubmitLoginFormHandler = async (account: { email: string, password: string }) => {
        const a = {
            email: account.email.toLocaleLowerCase(),
            password: account.password.toLocaleLowerCase()
        };
        const signInResult = await props.authStore.signIn(a)
        if (signInResult.isError) return setIsOpenDialog(true)
        const getUserResult = await props.userStore.getUser()
        if (getUserResult.isError) return setIsOpenDialog(true)
        props.onNavigate('/')
    }

    const closeDialogHandler = () => setIsOpenDialog(false)

    return (
        <Box
            style={{
                backgroundImage: `url(${props.publicUrl}/img/companeros.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "100vh",
                width: "100%",
            }}
        >
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                    height: "100vh",
                    width: "100%",
                }}
            >
                <LoginFormComponent publicUrl={props.publicUrl} onSubmit={onSubmitLoginFormHandler} />
            </Grid>

            <Dialog open={isOpenDialog} onClose={closeDialogHandler}>
                <DialogTitle color="error">Error para acceder a su cuenta</DialogTitle>
                <DialogContent>
                    <DialogContentText>Su email u password son incorrectos. Por favor ingrese sus datos nuevamente.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogHandler}>Aceptar</Button>
                </DialogActions>
            </Dialog>

        </Box >

    )
}
