
export interface IResult<GResult> {
    isSuccess: boolean
    isError: boolean
    data: GResult
}


export interface IResultUtil {
    success: <GResult>(data: GResult) => IResult<GResult>
    error: <GResult>(data: GResult) => IResult<GResult>
}

const useResultUtil = (): IResultUtil => {

    const success = <GResult>(data: GResult): IResult<GResult> => ({
        isSuccess: true,
        isError: false,
        data: data
    })

    const error = <GResult>(data: GResult): IResult<GResult> => ({
        isSuccess: false,
        isError: true,
        data: data
    })

    return {success, error}
}


export { useResultUtil }