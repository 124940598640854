import { IActivity } from "../models/activity.model"
import { IResponseUtil } from "../utils/useResponse.util"
import { IServiceResponse, IServiceUtil } from "../utils/useServices.util"
import { ITokenUtil } from "../utils/useToken.util"

interface IUseActivityServiceProps {
    baseUrl: string
    serviceUtil: IServiceUtil
    responseUtil: IResponseUtil
    tokenUtil: ITokenUtil
}

export interface IActivityService {
    getList: () => Promise<IServiceResponse<Array<IActivity>>>
    update: (activity: IActivity) =>  Promise<IServiceResponse<null>>
}

export const useActivityService = (props: IUseActivityServiceProps): IActivityService => {

    const getList: IActivityService['getList'] = () => {
        // falta la seguridad
        const response = props.serviceUtil.get<Array<IActivity>>({
            url: `${props.baseUrl}/activities`,
            headers: {
                Authorization: `${props.tokenUtil.get()}`
            }
        })

        return response
    }

    const update: IActivityService['update'] = activity=> {
        const response = props.serviceUtil.put<null>({
            url: `${props.baseUrl}/activity/${activity.id}`,
            data: activity,
            headers: {
                Authorization: `${props.tokenUtil.get()}`
            }
        })
        return response
    }

    return { getList, update }
}
