import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { PaginatedComponent } from "../components/paginated.component";
import { IActivityStore } from "../stores/useActivity.store";
import { IFileStore } from "../stores/useFile.store";
import { IUserStore } from "../stores/useUser.store";
import { ActivityCardComponent } from "../components/activityCard.component";
import { IActivity } from "../models/activity.model";

interface IActivityViewProps {
    fileStore: IFileStore
    userStore: IUserStore
    activityStore: IActivityStore
}

export const ActivitiesView: FC<IActivityViewProps> = props => {

    const [childSelectedId, setChildSelectedId] = useState<number>(0)

    const [currentPageState, setCurrenPageState] = useState<number>(0)
    const [rowsPerPageState, setRowsPerPageState] = useState<number>(3)
    const [searchedActivityState, setSearchedActivityState] = useState<string>("")

    const onChangePageHandler = (value: number) => setCurrenPageState(--value)

    const onChangeRowsPerPageHandler = (value: number) => {
        setCurrenPageState(0)
        setRowsPerPageState(value)
    }

    // me falta el input search XD
    const onChangeInputSearchHandler = (value: string) => {
        setSearchedActivityState(value)
    }

    useEffect(() => {
        onInitHandler()
    }, [])

    const onInitHandler = async () => {
        if (props.userStore.user.role === 'student') return props.activityStore.getActivityList(props.userStore.user.id)
        if (props.userStore.user.role === 'parent') {
            const getChildrenResult = await props.userStore.getChildren(props.userStore.user.childrenId)
            if (getChildrenResult.isError) return
            setChildSelectedId(getChildrenResult.data[0].id)
            await props.activityStore.getActivityList(getChildrenResult.data[0].id)
        }
    }

    const onChangeChildrenHandler = (event: SelectChangeEvent<number>) => {
        const userId = Number(event.target.value)
        setChildSelectedId(Number(userId))
        props.activityStore.getActivityList(userId)
    }

    const onOpenLinkHandler = (activity: IActivity) => window.open(activity.url)

    const onUploadLinkHandler = () => { }

    const onUploadFileHandler = () => { }

    const onDownloadFileHandler = () => { }

    const activityListFilteredComputed = searchedActivityState ? props.activityStore.activityList.filter(activity =>
        activity.title.toLowerCase().includes(searchedActivityState.toLowerCase()))
        : props.activityStore.activityList

    const totalPagesComputed = Math.ceil(activityListFilteredComputed.length / rowsPerPageState)

    const initPagePositionComputed = currentPageState * rowsPerPageState

    const endPagePositionComputed = initPagePositionComputed + rowsPerPageState

    const bookListPaginatedComputed = activityListFilteredComputed.filter(
        (_, index) => index >= initPagePositionComputed && index < endPagePositionComputed
    )

    return (
        <Box padding={5}>

            {props.userStore.user.role === 'parent' && (
                <Grid container padding="0px 0px 20px 0px">
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel id="childrenSelectLabel">Niño/a</InputLabel>
                            <Select
                                labelId="childrenSelectLabel"
                                id="childrenSelect"
                                value={childSelectedId}
                                label="Niño/a"
                                onChange={onChangeChildrenHandler}
                            >
                                {props.userStore.children.map(child => (
                                    <MenuItem value={child.id} key={child.id}>{child.email}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )}

            <Grid container spacing={5} >
                {bookListPaginatedComputed.map(activity => (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={activity.id}>
                        <ActivityCardComponent
                            activity={activity}
                            onOpenLink={onOpenLinkHandler}
                            onUploadLink={onUploadLinkHandler}
                            onUploadFile={onUploadFileHandler}
                            onDownloadFile={onDownloadFileHandler}
                        />
                    </Grid>
                ))}
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <PaginatedComponent
                    currentPage={currentPageState}
                    totalPages={totalPagesComputed}
                    onChangePage={onChangePageHandler}
                    rowsPerPage={rowsPerPageState}
                    onChangeRowsPerPage={onChangeRowsPerPageHandler}
                />
            </Grid>
        </Box >

    )
}

