import { FC, useEffect, useState } from "react"
import PdfComponent from "../components/pdf.component"
import { bookFactory } from "../factories/book.factory"
import { IBook } from "../models/book.model"
import { IBookStore } from "../stores/useBook.store"

interface IBookViewProps {
    id: number
    bookStore: IBookStore
}

const styles = {
    width: '98%',
    height: '968px'
};

export const BookView: FC<IBookViewProps> = props => {

    const [bookState, setBookState] = useState<IBook>(bookFactory())

    const onInit = async () => {
        const result = await props.bookStore.getBook(props.id)
        if (result.isError) return
        setBookState(result.data)
    }

    useEffect(() => {
        onInit()
    }, [props.id])

    return (
        bookState.url && bookState.status.enable ? (
            <iframe className="no-screenshot" src={bookState.url} title="pepe" style={styles} />
        ) : null 
    )
}
