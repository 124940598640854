import { Route } from "react-router";
import { IStore } from "../stores";
import { SignInView } from "../views/SignIn.view";


interface IUseSignOutStackProps {
    publicUrl: string
    store: IStore
}

export const signOutStack = (props: IUseSignOutStackProps) => [
    <Route key="/" path="/" exact render={(routeParam) => <SignInView publicUrl={props.publicUrl} authStore={props.store.auth} userStore={props.store.user} onNavigate={routeParam.history.push}/>} />,
    <Route key="/signIn" path="/signIn" exact render={(routeParam) => <SignInView publicUrl={props.publicUrl} authStore={props.store.auth} userStore={props.store.user} onNavigate={routeParam.history.push} />} />,
    <Route key="*" path="*" render={() => <h1>404</h1>} />
]