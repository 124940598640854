import axios, { AxiosResponse } from 'axios'
import { useRef } from 'react'

export interface IServiceResponse<GData> {
    isSuccess: boolean
    isError: boolean
    status: number
    data: GData
}

const sendRequest = <GData>(request: {
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
    url: string,
    data: any,
    headers: any,
    onApi: (props: { url: string, status: 'init' | 'success' | 'error' }) => void
}): Promise<IServiceResponse<GData>> => {
    request.onApi({ url: request.url, status: 'init' })
    return axios<GData>({
        method: request.method,
        url: request.url,
        data: request.data,
        headers: request.headers
    })
        .then((result: AxiosResponse<GData>) => {

            const response: IServiceResponse<GData> = {
                isSuccess: true,
                isError: false,
                status: result.status,
                data: result.data,
            }
            
            request.onApi({ url: request.url, status: 'success' })
            return response
        })
        .catch((error: AxiosResponse<GData>) => {

            const response: IServiceResponse<GData> = {
                isSuccess: false,
                isError: true,
                status: error.status,
                data: error.data,
            }
            request.onApi({ url: request.url, status: 'error' })
            return response
        })
}



export interface IServiceUtil {
    get: <GData>(props: { url: string, headers: any }) => Promise<IServiceResponse<GData>>;
    post: <GData>(props: {
        url: string;
        data: any;
        headers: any;
    }) => Promise<IServiceResponse<GData>>;
    put: <GData>(props: {
        url: string;
        data: any;
        headers: any;
    }) => Promise<IServiceResponse<GData>>;
    patch: <GData>(props: {
        url: string;
        data: any;
        headers: any;
    }) => Promise<IServiceResponse<GData>>;
    del: <GData>(props: {
        url: string;
        headers: any;
    }) => Promise<IServiceResponse<GData>>;
    addCallback: (
        cv: (props: { url: string, status: 'init' | 'success' | 'error' }) => void
    )=> void
}

const useServiceUtil = (): IServiceUtil => {

    const callback = useRef<((props: { url: string, status: 'init' | 'success' | 'error' }) => void)>(()=>{})

    const addCallback = (cb: (props: { url: string, status: 'init' | 'success' | 'error' }) => void) => {
        callback.current = cb
    }

    const get: IServiceUtil['get'] = <GData>(request: { url: string; headers: any }) => sendRequest <GData>({
        method: 'GET',
        url: request.url,
        data: null,
        headers: request.headers,
        onApi: callback.current,
    })

    const post: IServiceUtil['post'] = <GData>(request: { url: string; data: any; headers: any }) => sendRequest<GData>({
        method: 'POST',
        url: request.url,
        data: request.data,
        headers: request.headers,
        onApi: callback.current,
    })

    const put: IServiceUtil['put'] = <GData>(request: { url: string; data: any; headers: any }) => sendRequest<GData>({
        method: 'PUT',
        url: request.url,
        data: request.data,
        headers: request.headers,
        onApi: callback.current,
    })

    const patch: IServiceUtil['patch'] = <GData>(request: { url: string; data: any; headers: any }) => sendRequest<GData>({
        method: 'PATCH',
        url: request.url,
        data: request.data,
        headers: request.headers,
        onApi: callback.current,
    })

    const del: IServiceUtil['del'] = <GData>(request: { url: string; headers: any }) => sendRequest<GData>({
        method: 'DELETE',
        url: request.url,
        data: null,
        headers: request.headers,
        onApi: callback.current,
    })

    return { get, post, put, patch, del, addCallback }
}

export { useServiceUtil }