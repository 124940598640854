import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import * as React from "react";
import { IStore } from "./stores";

import { BooksView } from "./views/Books.view";
import { SignInView } from "./views/SignIn.view";
import { NavigationComponent } from "./components/navigation.component";
import { BookView } from "./views/Book.view";
import { signOutStack } from "./stacks/signOut.stack";
import { signInStack } from "./stacks/signIn.stack";

interface IRouterProps {
  publicUrl: string
  store: IStore
}

const Router: React.FC<IRouterProps> = props => {

  return (

    <BrowserRouter>
    
      <NavigationComponent authStore={props.store.auth} userStore={props.store.user}/>

      {props.store.auth.isAuthenticated === true ? (
        <Switch>{
          signInStack({publicUrl: props.publicUrl, store: props.store})
        }</Switch>
      ) : null}

      {props.store.auth.isAuthenticated === false ? (
        <Switch>{
            signOutStack({publicUrl: props.publicUrl, store: props.store})
        }</Switch>
      ) : null}

    </BrowserRouter>
  )
}





export default Router;