export interface IEnvironmentVarsUtil {
    bookApiUrl: string
    authApiUrl: string
    userApiUrl: string
    activityUrl: string
    fileUrl: string

    publicUrl: string
    country: string
    lang: string
}

export const useEnvironmentVarsUtil = (): IEnvironmentVarsUtil => ({
    bookApiUrl: String(process.env.REACT_APP_BOOK_API_URL),
    activityUrl: String(process.env.REACT_APP_API_ACTIVITY_URL),
    authApiUrl: String(process.env.REACT_APP_API_AUTH_URL),
    userApiUrl: String(process.env.REACT_APP_API_USER_URL),
    fileUrl: String(process.env.REACT_APP_API_FILE_URL),
    publicUrl: process.env.PUBLIC_URL,
    country: String(process.env.REACT_APP_API_COUNTRY),
    lang: String(process.env.REACT_APP_LANG)
})






