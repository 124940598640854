import { useState } from "react"
import { IAuthService } from "../services/useAuth.service"
import { IResult, IResultUtil } from "../utils/useResult.util"
import { ITokenUtil } from "../utils/useToken.util"

interface IUseAuthStoreProps {
    resultUtil: IResultUtil
    authService: IAuthService
    tokenUtil: ITokenUtil
}


export interface IAuthStore {
    isAuthenticated: boolean
    signIn: (account: { email: string, password: string }) => Promise<IResult<string>>
    signOut: () => Promise<IResult<null>>
    signInCheck: () => Promise<IResult<null>>
}


export const useAuthStore = (props: IUseAuthStoreProps): IAuthStore => {

    const [isAuthenticated, setIsAuthenticated] = useState<IAuthStore['isAuthenticated']>(false)

    const signIn: IAuthStore['signIn'] = async (account) => {
        const response = await props.authService.signIn(account)

        if (response.isError) return props.resultUtil.error('')
        if (response.status && response.data === '') return props.resultUtil.error('')

        setIsAuthenticated(true)
        props.tokenUtil.set(response.data)
        return props.resultUtil.success(response.data)
    }

    const signOut: IAuthStore['signOut'] = async () => {
        setIsAuthenticated(false)
        props.tokenUtil.set("")
        return props.resultUtil.success(null)
    }

    const signInCheck: IAuthStore['signInCheck'] = async () => {
        const tokenObtained = props.tokenUtil.get()
        if (tokenObtained === '') {
            setIsAuthenticated(false)
            return props.resultUtil.error(null)
        }

        setIsAuthenticated(true)
        return props.resultUtil.success(null)
    }

    return {
        isAuthenticated,
        signIn,
        signOut,
        signInCheck
    }
}


