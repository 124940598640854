import { Route } from "react-router"
import { IStore } from "../stores"
import { ActivitiesView } from "../views/Activities.view"
import { BookView } from "../views/Book.view"
import { BooksView } from "../views/Books.view"

interface IUseSignInStackProps {
    publicUrl: string
    store: IStore
}

export const signInStack = (props: IUseSignInStackProps) => {

    return [
        <Route
            key="/"
            path="/"
            exact
            render={(routeParam) => {
                if(props.store.user.user.id && props.store.user.user.role === 'professor' || props.store.user.user.role === "admin" || props.store.user.user.role === "parent") return (
                    <BooksView bookStore={props.store.book} onNavigate={routeParam.history.push} />
                )
                if (props.store.user.user.id && props.store.user.user.role === 'student') return (
                    <ActivitiesView activityStore={props.store.activity} userStore={props.store.user} fileStore={props.store.file} />
                )
                return (<></>)
            }}
        />,
        <Route
            key="/books"
            path="/books"
            exact
            render={(routeParam) => {
                return (<BooksView bookStore={props.store.book} onNavigate={routeParam.history.push} />)
            }}
        />,
        <Route
            key="/book/:id"
            path="/book/:id"
            exact
            render={(routeProps) => {
                return (<BookView
                    id={Number(routeProps.match.params.id)}
                    bookStore={props.store.book}
                />);
            }}
        />,
        <Route
            key="/activities"
            path="/activities"
            exact
            render={() => {
                if (props.store.user.user.id && props.store.user.user.role === 'student') return (
                    <ActivitiesView
                        activityStore={props.store.activity}
                        userStore={props.store.user}
                        fileStore={props.store.file}
                    />
                )
                return (<h1>Forbidden</h1>)
            }}
        />,
        <Route key="*" path="*" render={() => <h1>404</h1>} />
    ]

}

