import { Card, Grid, CardHeader, Avatar, CardMedia, CardContent, Typography, CardActions, Button } from "@mui/material"
import { deepOrange } from "@mui/material/colors"
import { FC } from "react"
import { IActivity } from "../models/activity.model"

interface IActivityCardComponentProps {
    activity: IActivity
    onUploadFile: (activity: IActivity) => void
    onDownloadFile: (activity: IActivity) => void
    onUploadLink: (activity: IActivity) => void
    onOpenLink: (activity: IActivity) => void
}

export const ActivityCardComponent: FC<IActivityCardComponentProps> = props => {

    const onClickHandler = (): void => {
        if (props.activity.type === "ATTACHMENT" && props.activity.done) return props.onDownloadFile(props.activity)
        if (props.activity.type === "ATTACHMENT" && !props.activity.done) return props.onUploadFile(props.activity)
        if (props.activity.type === "LINK" && props.activity.done) return props.onOpenLink(props.activity)
        else return props.onUploadLink(props.activity)
    }

    const getCurrentText = (): string => {
        if (props.activity.type === "ATTACHMENT" && props.activity.done === false) return "Subir archivo"
        if (props.activity.type === "ATTACHMENT" && props.activity.done) return "Ver archivo"
        if (props.activity.type === "LINK" && props.activity.done === false) return "Subir Link"
        else return "Ver link"
    }

    return (
        <Card sx={{ borderRadius: 3, height: "100%" }}>
            <Grid container flexDirection="column" display="flex" justifyContent="space-around" sx={{ height: "100%" }}>
                <Grid item >
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: deepOrange[500] }}>A</Avatar>
                        }
                        title="Autung"
                        titleTypographyProps={{ fontWeight: "500", fontSize: "20px" }}
                    />
                </Grid>

                <Grid item>
                    <CardMedia
                        component="img"
                        height="194"
                        image="https://autung.sfo3.cdn.digitaloceanspaces.com/scratch/cover.jpg"
                        alt="cover"
                    />
                </Grid>
                <Grid item>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6">{props.activity.title}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Grid>
                <Grid item alignSelf="self-end">
                    <CardActions>

                        <Button
                            color="secondary"
                            size="medium"
                            variant="outlined"
                            sx={{ borderRadius: 28, borderColor: "grey.500" }}
                            onClick={() => onClickHandler()}
                        >
                            {getCurrentText()}
                        </Button>
                    </CardActions>
                </Grid>
            </Grid>
        </Card >
    )
}